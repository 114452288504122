<template>
  <v-app>
    <v-banner
        single-line
        height="400"
        class="children-banner"
    >
      <v-carousel height="100%"
                  class="carousel-new carousel-banner"
                  hide-delimiter-background
                  hide-delimiters
                  :show-arrows="false">
        <v-carousel-item v-for="(banner,index) in bannerList" :key="index">
          <v-img
              :src="banner.img"
              height="100%"
          >
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    size="80"
                    width="8"
                    color="grey lighten-3"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-container color="transparent" class="pa-0 max-width-1200 height-per-100 d-flex align-center justify-center">
              <v-card color="transparent" class="ml-0" flat>
                <v-card-title class="pa-0 font-size-banner-title family-Bold justify-center" :style="'color: ' + banner.titleColor + '!important;'">
                  <div>
                    <div v-for="(title,tipIndex) in banner.title" :key="tipIndex" class="pt-2" style="font-weight: 500">{{title}}</div>
                  </div>
                </v-card-title>
                <v-card-title class="pa-0 font-size-banner-tip mt-4 justify-center" :style="'color: ' + banner.titleColor + '!important;'">
                  {{banner.tip}}
                </v-card-title>
                <v-card-actions class="pa-0 mt-14 justify-center" v-if="banner.btn">
                  <v-btn
                      rounded
                      color="#0568FD"
                      width="160"
                      height="50"
                      class="font-size-20"
                      @click.stop="handleBannerBtn(banner.router)"
                  >
                    <span class="color-FFF">{{ banner.btn }}</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-container>
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-banner>
    <div class="d-flex justify-center width-per-100">
      <v-card class="max-width-1200 width-per-100" flat style="width:1200px;margin:o auto;">
        <div class="d-flex justify-center pt-15" style="width:1200px;margin:0 auto;">
          <transition name="slide-fade" appear>
            <v-img style="background-color: transparent"
                   width="400" max-width="400" max-height="260" min-height="260"
                   src="https://h5.ophyer.cn/official_website/other/MDS01.png">
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      size="80"
                      width="8"
                      color="grey lighten-3"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </transition>
          <transition name="slide-fade-reverse" appear>
            <div style="margin-left: 80px;width: 385px;"
                 class="d-flex align-center">
              <div>
                <v-card-title class="pa-0 font-size-24 font-weight-bold white-space-pre-wrap" style="line-height: 1.2">AR/VR趣味运营内容</v-card-title>
                <v-card-text class="pa-0 mt-4 font-size-14 color-666">
                  <div class="width-per-100 white-space-pre-wrap">多种玩法的AR互动游戏以及VR全景沉浸式内容展示，提升用户量扩大品牌影响力、提升运营效果</div>
                </v-card-text>
              </div>
            </div>
          </transition>
        </div>
        <div class="d-flex justify-center pt-15">
          <transition name="slide-fade-reverse" appear>
            <div style="margin-right: 80px;width: 385px;"
                 class="d-flex align-center">
              <div>
                <v-card-title class="pa-0 font-size-24 font-weight-bold white-space-pre-wrap" style="line-height: 1.2">精准定位目标人群</v-card-title>
                <v-card-text class="pa-0 mt-4 font-size-14 color-666">
                  <div class="width-per-100 white-space-pre-wrap">自动托管，通过强大的计算能力，精准定位目标人群，锁定目标用户，通过算法引擎，大幅提升ROI</div>
                </v-card-text>
              </div>
            </div>
          </transition>
          <transition name="slide-fade" appear>
            <v-img style="background-color: transparent"
                   width="400" max-width="400" max-height="260" min-height="260"
                   src="https://h5.ophyer.cn/official_website/other/MDS02.png">
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      size="80"
                      width="8"
                      color="grey lighten-3"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </transition>
        </div>
        <div class="d-flex justify-center pt-15">
          <transition name="slide-fade" appear>
            <v-img style="background-color: transparent"
                   width="400" max-width="400" max-height="260" min-height="260"
                   src="https://h5.ophyer.cn/official_website/other/MDS03.png">
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      size="80"
                      width="8"
                      color="grey lighten-3"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </transition>
          <transition name="slide-fade-reverse" appear>
            <div style="margin-left: 80px;width: 385px;"
                 class="d-flex align-center">
              <div>
                <v-card-title class="pa-0 font-size-24 font-weight-bold white-space-pre-wrap" style="line-height: 1.2">运营阵地</v-card-title>
                <v-card-text class="pa-0 mt-4 font-size-14 color-666">
                  <div class="width-per-100 white-space-pre-wrap">对接巨量引擎、广点通、百度信息流等主流媒体投放平台</div>
                </v-card-text>
              </div>
            </div>
          </transition>
        </div>
        <div class="d-flex justify-center pt-15">
          <transition name="slide-fade-reverse" appear>
            <div style="margin-right: 80px;width: 385px;"
                 class="d-flex align-center">
              <div>
                <v-card-title class="pa-0 font-size-24 font-weight-bold white-space-pre-wrap" style="line-height: 1.2">结算更透明</v-card-title>
                <v-card-text class="pa-0 mt-4 font-size-14 color-666">
                  <div class="width-per-100 white-space-pre-wrap">始终与客户利益保持一致，价格透明、数据透明、流程透明；美数承诺仅收取平台技术服务费，绝不赚取中间差价、不扣量</div>
                </v-card-text>
              </div>
            </div>
          </transition>
          <transition name="slide-fade" appear>
            <v-img style="background-color: transparent"
                  width="400"  max-width="400" max-height="260" min-height="260"
                   src="https://h5.ophyer.cn/official_website/other/MDS04.png">
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      size="80"
                      width="8"
                      color="grey lighten-3"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </transition>
        </div>
      </v-card>
    </div>
    <div class="d-flex justify-center py-15">
      <v-card class="max-width-1200" flat>
        <div class="public-title mt-0">
          <p>PRODUCT</p>
          <div>
            <span>相关产品</span>
          </div>
        </div>
        <div class="d-flex justify-center mt-1">
          <v-card flat
                  v-for="(info,index) in productList" :key="index"
                  :class="['product-card'+index,index === 0 ? '':'ml-15']"
                  :to="info.router"
                  style="width: 300px;height: 400px;padding: 50px 23px 26px 23px;">
            <v-card-title class="pa-0 font-size-24 color-FFF font-weight-bold">{{info.title}}</v-card-title>
            <v-card-text class="pa-0 mt-4 font-size-14 color-FFF" style="line-height: 20px;height: 40px;">{{info.tip}}</v-card-text>
            <v-img contain :src="info.img" height="180" class="mt-15"></v-img>
          </v-card>
        </div>
      </v-card>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "ServiceContent",
  components: {
  },
  data() {
    return {
      tab:null,
      bannerList: [
        {
          img: "https://h5.ophyer.cn/official_website/banner/MDS-banner.png",
          title: ["为什么选择我们？"],
          titleColor:"#FFFFFF",
          tip: "近80万家APP媒体，日均曝光150亿次",
        }
      ],
      data: {},
      currentIndex: 0,
      productList: [
        {
          title: "AI智能",
          router: "/AI/AIHome",
          tip: "源于实践的行业方案，赋能产业AI升级",
          img: "https://h5.ophyer.cn/official_website/other/MDS05.png",
        },
        {
          title: "大数据",
          router: "/bigDataAnalysis/home",
          tip: "通过多维用户洞察，灵活多维度分析，为企业量身打造综合性运营解决方案",
          img: "https://h5.ophyer.cn/official_website/other/MDS06.png",
        },
        {
          title: "视听云",
          router: "/audiovisualCloud/home",
          tip: "简单易用，视听业务更安全合规，让企业安心开展直播、点播业务",
          img: "https://h5.ophyer.cn/official_website/other/MDS07.png",
        }
      ]
    }
  },
  created() {
  },
  methods: {
    change(index){
      this.currentIndex = index;
    },
  }
}
</script>

<style scoped>
.product-card0{
  background: linear-gradient(180deg, #688AF3, #B7DDFB);
  border-radius: 10px;
}
.product-card1{
  background: linear-gradient(180deg, #34A0FE, #7ECCFE);
  border-radius: 10px;
}
.product-card2{
  background: linear-gradient(180deg, #688AF3, #B7DDFB);
  border-radius: 10px;
}
</style>